<template>
  <a-table
    border
    rowKey="id"
    size="middle"
    :loading="isLoading"
    :columns="columns"
    :data-source="source"
    :pagination="pagination"
    @change="loadList"
  >
    <template #op="{ record }">
      <a-button class="mr" type="primary" size="small" @click="handleView(record)">查看帖子</a-button>
      <a-button class="mr" type="primary" size="small" @click="handleConfirm(record)">通过</a-button>
      <a-button type="primary" size="small" @click="handleCancel(record)">拒绝</a-button>
    </template>
  </a-table>
</template>

<script>
import { onMounted, ref } from "vue";
import { page, update } from "../api/report";
import { message } from "ant-design-vue";
import urls from "../common/urls";

export default {
  setup() {
    const columns = [
      {
        title: "ID",
        dataIndex: "logicId",
        width: "25%"
      },
      {
        title: "举报理由",
        dataIndex: "reason",
        width: "50%"
      },
      {
        title: "操作",
        dataIndex: "op",
        width: "25%",
        slots: { customRender: "op" }
      }
    ];
    const isLoading = ref(false);
    const source = ref([]);
    const pagination = ref({
      total: 0,
      defaultPageSize: 20,
      pageSizeOptions: ["10", "15", "20", "30"],
      showSizeChanger: true,
      showQuickJumper: true,
      showTotal: total => `共 ${total} 条`,
      hideOnSinglePage: true
    });
    const handleView = obj => {
      window.open(`${urls.h5}/pages/goods/post/goodsPost?id=${obj.postId}`);
    };
    const handleConfirm = async obj => {
      const res = await update({ logicId: obj.logicId, status: 1 });
      if (res.status == 200) {
        message.success("操作成功！");
      }
      loadList();
    };
    const handleCancel = async obj => {
      const res = await update({ logicId: obj.logicId, status: 0 });
      if (res.status == 200) {
        message.success("操作成功！");
      }
      loadList();
    };
    const loadList = async pageObj => {
      isLoading.value = true;
      const { current, pageSize } = pageObj || {};
      const { data: res } = await page({
        pageNum: current || 1,
        pageSize: pageSize || pagination.value.defaultPageSize
      });
      isLoading.value = false;
      if (res) {
        const { dataOnThisPage, total } = res;
        source.value = dataOnThisPage;
        pagination.value.total = total;
      }
    };

    onMounted(loadList);

    return {
      isLoading,
      columns,
      source,
      pagination,
      handleView,
      handleConfirm,
      handleCancel,
      loadList
    };
  }
};
</script>

<style lang="scss" scoped>
.mr {
  margin-right: 10px;
}
</style>
