import { stringify } from 'qs'
import { request } from '../utils/axios'

export function page(params) {
  return request({
    url: `/wuan/admin/post/report/list?${stringify(params)}`,
    method: 'get'
  })
}

export function update(params) {
  return request({
    url: `/wuan/admin/post/report/update`,
    method: 'post',
    params
  })
}